<template>
  <div class="content pad-bottom--vxl">
    <div class="grid-container pad-top--xxl">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-8 medium-offset-2 pad-top--xxl">
          <h1 class="text-white ">Reset password request</h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-8 medium-offset-2">
          <p class="text-white">Please enter your email below and we will send you a link to reset your password.</p>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6 medium-offset-2">
          <input
            v-model="email"
            class="bg--transparent"
            type="text"
            required
            placeholder="Email" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pb-10">
        <div class="cell small-6 medium-3 medium-offset-2">
          <button @click="requestReset" class="button rounded-tall expanded">Send</button>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6 medium-offset-2">
          <div
            @click="message = ''"
            v-if="message.length > 0"
            class="message text-white">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from '../axios';
  
  export default {
    name: 'ForgotPassword',
    data() {
      return {
        email: '',
        message: '',
      };
    },
    methods: {
      requestReset() {
        const postData = {};
        postData.email = this.email;
        axios.post('/users/requestPasswordReset.json', postData)
          .then((response) => {
            this.message = response.data;
          });
      },
    },
  };
  </script>
  